import { Retrier } from "@twilio/operation-retrier";
import { Transport, TransportResult } from "twilsock";
import { Configuration } from "../configuration";

import Timeout = NodeJS.Timeout;

interface CacheEntry {
  response: TransportResult<unknown>;
  timestamp: number;
}

export interface NetworkServices {
  transport: Transport;
}

class Network {
  private readonly configuration: Configuration;
  private readonly services: NetworkServices;
  private cacheLifetime: number;

  private readonly cache: Map<string, CacheEntry>;
  private timer!: number | NodeJS.Timeout;

  constructor(configuration, services) {
    this.configuration = configuration;
    this.services = services;
    this.cache = new Map<string, CacheEntry>();
    this.cacheLifetime = this.configuration.httpCacheInterval * 100;
    this.cleanupCache();
  }

  private isExpired(timestamp: number): boolean {
    return !this.cacheLifetime || Date.now() - timestamp > this.cacheLifetime;
  }

  private cleanupCache() {
    for (const [k, v] of this.cache) {
      if (this.isExpired(v.timestamp)) {
        this.cache.delete(k);
      }
    }

    if (this.cache.size === 0) {
      clearInterval(this.timer as Timeout);
    }
  }

  pokeTimer() {
    this.timer =
      this.timer ||
      setInterval(() => this.cleanupCache(), this.cacheLifetime * 2);
  }

  private executeWithRetry<T>(
    request,
    retryWhenThrottled = false
  ): Promise<TransportResult<T>> {
    return new Promise((resolve, reject) => {
      const codesToRetryOn = [502, 503, 504];
      if (retryWhenThrottled) {
        codesToRetryOn.push(429);
      }

      const retrier = new Retrier(this.configuration.backoffConfiguration);
      retrier.on("attempt", () => {
        request()
          .then((result) => retrier.succeeded(result))
          .catch((err) => {
            if (codesToRetryOn.indexOf(err.status) > -1) {
              retrier.failed(err);
            } else if (err.message === "Twilsock disconnected") {
              // Ugly hack. We must make a proper exceptions for twilsock
              retrier.failed(err);
            } else {
              // Fatal error
              retrier.removeAllListeners();
              retrier.cancel();
              reject(err);
            }
          });
      });

      retrier.on("succeeded", (result) => {
        resolve(result);
      });
      retrier.on("cancelled", (err) => reject(err));
      retrier.on("failed", (err) => reject(err));

      retrier.start();
    });
  }

  async get<T>(url: string): Promise<TransportResult<T>> {
    const cacheEntry = this.cache.get(url);
    if (cacheEntry && !this.isExpired(cacheEntry.timestamp)) {
      return cacheEntry.response as TransportResult<T>;
    }

    const headers = {};
    const response = await this.executeWithRetry<T>(
      () =>
        this.services.transport.get<T>(
          url,
          headers,
          this.configuration.productId
        ),
      this.configuration.retryWhenThrottled
    );
    this.cache.set(url, { response, timestamp: Date.now() });
    this.pokeTimer();
    return response;
  }
}

export { Network };
