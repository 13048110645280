import {
  McsClient,
  McsMedia,
  MediaCategory as McsMediaCategory,
} from "@twilio/mcs-client";

/**
 * Category of media. Possible values are as follows:
 * * `'media'`
 * * `'body'`
 * * `'history'`
 */
type MediaCategory = McsMediaCategory;

interface MediaState {
  sid: string;
  category: MediaCategory;
  filename: string | null;
  contentType: string;
  size: number;
}

interface MediaServices {
  mcsClient: McsClient;
}

/**
 * Represents a media information for a message in a conversation.
 */
class Media {
  private state: MediaState;
  private services: MediaServices;
  private mcsMedia: McsMedia | null = null;

  /**
   * @internal
   */
  constructor(data: MediaState | McsMedia, services: MediaServices) {
    this.services = services;

    if (data instanceof McsMedia) {
      this.mcsMedia = data as McsMedia;
    }

    this.state = {
      sid: data.sid,
      category: data.category,
      filename: data.filename,
      contentType: data.contentType,
      size: data.size,
    };
  }

  /**
   * Server-assigned unique identifier for the media.
   */
  public get sid(): string {
    return this.state.sid;
  }

  /**
   * File name. Null if absent.
   */
  public get filename(): string | null {
    return this.state.filename;
  }

  /**
   * Content type of the media.
   */
  public get contentType(): string {
    return this.state.contentType;
  }

  /**
   * Size of the media in bytes.
   */
  public get size(): number {
    return this.state.size;
  }

  /**
   * Media category, can be one of the {@link MediaCategory} values.
   */
  public get category(): MediaCategory {
    return this.state.category;
  }

  /**
   * Returns the direct content URL for the media.
   *
   * This URL is impermanent, it will expire in several minutes and cannot be cached.
   * If the URL becomes expired, you need to request a new one.
   * Each call to this function produces a new temporary URL.
   */
  public async getContentTemporaryUrl(): Promise<string | null> {
    await this._fetchMcsMedia();
    return this.mcsMedia?.getContentUrl() ?? null;
  }

  /**
   * Returns cached direct content URL for the media.
   *
   * This URL will expire in several minutes. This function does not refresh the URL and can be used to query it several times
   * without causing network traffic.
   * If the URL becomes expired, you need to request a new one using getContentTemporaryUrl().
   *
   * @returns {Promise<String>}
   */
  public async getCachedTemporaryUrl(): Promise<string | null> {
    await this._fetchMcsMedia();
    return this.mcsMedia?.getCachedContentUrl() ?? null;
  }

  private async _fetchMcsMedia() {
    if (!this.mcsMedia) {
      if (this.services.mcsClient) {
        this.mcsMedia = await this.services.mcsClient.get(this.state.sid);
      } else {
        throw new Error("Media Content Service is unavailable");
      }
    }
  }
}

export { Media, MediaState, MediaServices, MediaCategory };
