import { Logger } from "./logger";
import { Configuration } from "./configuration";

import { User, UserUpdatedEventArgs, UserUpdateReason } from "./user";
import { Network } from "./services/network";

import { NotificationTypes } from "./interfaces/notification-types";

import {
  TwilsockClient,
  InitRegistration,
  ConnectionState as TwilsockConnectionState,
  Transport,
} from "twilsock";
import {
  ChannelType,
  Notifications as NotificationClient,
} from "@twilio/notifications";
import { SyncClient } from "twilio-sync";
import { McsClient } from "@twilio/mcs-client";

import {
  Conversation,
  Conversations,
  Conversations as ConversationsEntity,
} from "./data/conversations";

import { Users } from "./data/users";
import { TypingIndicator } from "./services/typing-indicator";
import { Paginator } from "./interfaces/paginator";
import { PushNotification } from "./push-notification";
import { deepClone, parseToNumber } from "./util";
import {
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason,
} from "./participant";
import {
  Message,
  MessageUpdatedEventArgs,
  MessageUpdateReason,
} from "./message";
import { TelemetryEventDescription, TelemetryPoint } from "twilsock";
import {
  validateTypesAsync,
  validateTypes,
  literal,
  nonEmptyString,
  pureObject,
  objectSchema,
  validateConstructorTypes,
} from "@twilio/declarative-type-validator";
import { version } from "../package.json";
import {
  ConversationUpdatedEventArgs,
  ConversationUpdateReason,
} from "./conversation";
import { CommandExecutor } from "./command-executor";
import { ConfigurationResponse } from "./interfaces/commands/configuration";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import { JSONValue } from "./types";

const log = Logger.scope("Client");

const SDK_VERSION = version;

class ClientServices {
  commandExecutor!: CommandExecutor;
  twilsockClient!: TwilsockClient;
  users!: Users;
  notificationClient!: NotificationClient;
  network!: Network;
  typingIndicator!: TypingIndicator;
  syncClient!: SyncClient;
  mcsClient!: McsClient;
  transport!: Transport;
}

type ClientEvents = {
  conversationAdded: (conversation: Conversation) => void;
  conversationJoined: (conversation: Conversation) => void;
  conversationLeft: (conversation: Conversation) => void;
  conversationRemoved: (conversation: Conversation) => void;
  conversationUpdated: (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => void;
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  tokenAboutToExpire: (ttl: number) => void;
  tokenExpired: () => void;
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
  pushNotification: (pushNotification: PushNotification) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
  userUpdated: (data: {
    user: User;
    updateReasons: UserUpdateReason[];
  }) => void;
  stateChanged: (state: State) => void;
  connectionStateChanged: (state: TwilsockConnectionState) => void;
  connectionError: (data: {
    terminal: boolean;
    message: string;
    httpStatusCode?: number;
    errorCode?: number;
  }) => void;
};

/**
 * Connection state of the client. Possible values are as follows:
 * * `'connecting'` - client is offline and connection attempt is in process
 * * `'connected'` - client is online and ready
 * * `'disconnecting'` - client is going offline as disconnection is in process
 * * `'disconnected'` - client is offline and no connection attempt is in process
 * * `'denied'` - client connection is denied because of invalid JWT access token. User must refresh token in order to proceed
 */
type ConnectionState = TwilsockConnectionState;

/**
 * State of the client. Possible values are as follows:
 * * `'failed'` - the client failed to initialize
 * * `'initialized'` - the client successfully initialized
 */
type State = "failed" | "initialized";

/**
 * Notifications channel type. Possible values are as follows:
 * * `'fcm'`
 * * `'apn'`
 */
type NotificationsChannelType = ChannelType;

type LogLevel = "trace" | "debug" | "info" | "warn" | "error" | "silent";

/**
 * Conversations client options.
 */
interface ClientOptions {
  /**
   * The level of logging to enable.
   */
  logLevel?: LogLevel;
  region?: string;
  productId?: string;
  twilsockClient?: TwilsockClient;
  transport?: Transport;
  notificationsClient?: NotificationClient;
  syncClient?: SyncClient;
  typingIndicatorTimeoutOverride?: number;
  consumptionReportIntervalOverride?: string;
  httpCacheIntervalOverride?: string;
  userInfosToSubscribeOverride?: number;
  retryWhenThrottledOverride?: boolean;
  backoffConfigOverride?: Record<string, unknown>;
  Chat?: ClientOptions;
  IPMessaging?: ClientOptions;
  Sync?: Record<string, unknown>;
  Notification?: Record<string, unknown>;
  Twilsock?: Record<string, unknown>;
  clientMetadata?: Record<string, unknown>;
  initRegistrations?: InitRegistration[];
  disableDeepClone?: boolean;
  typingUri?: string;
  apiUri?: string;
}

/**
 * Options for {@link Client.createConversation}.
 */
interface CreateConversationOptions {
  /**
   * Any custom attributes to attach to the conversation.
   */
  attributes?: JSONValue;

  /**
   * A non-unique display name of the conversation.
   */
  friendlyName?: string;

  /**
   * A unique identifier of the conversation.
   */
  uniqueName?: string;
}

/**
 * A client is the starting point to the Twilio Conversations functionality.
 */
@validateConstructorTypes(nonEmptyString, [pureObject, "undefined"])
class Client extends ReplayEventEmitter<ClientEvents> {
  /**
   * Client connection state.
   */
  public connectionState: ConnectionState = "unknown";
  private conversationsPromise!: Promise<Conversations>;
  private _ensureReady!: Promise<void>;
  private _resolveEnsureReady!: () => void;
  private _rejectEnsureReady!: (err: Error) => void;
  private fpaToken: string;
  private configuration!: Configuration;
  private conversations!: Conversations;
  private readonly options: Partial<ClientOptions>;
  private services: ClientServices;
  private readonly _myself: User;

  /**
   * Current version of the Conversations client.
   */
  public static readonly version: string = SDK_VERSION;

  /**
   * Current version of the Conversations client.
   */
  public readonly version: string = SDK_VERSION;

  private static readonly supportedPushChannels: NotificationsChannelType[] = [
    "fcm",
    "apn",
  ];
  private static readonly supportedPushDataFields = {
    conversation_sid: "conversationSid",
    message_sid: "messageSid",
    message_index: "messageIndex",
  };

  /**
   * Returned Conversations instance is not yet fully initialized. Calling any operations will block until it is.
   * Use connection events to monitor when client becomes fully available (connectionStateChanged with state
   * 'connected') or not available (connectionStateChange with state 'denied', event tokenExpired, event connectionError).
   *
   * @param fpaToken Access token
   * @param options Options to customize the Client
   * @returns A not yet fully-initialized client.
   */
  public constructor(fpaToken: string, options: ClientOptions | null = {}) {
    super();

    this.fpaToken = fpaToken ?? "";
    this.options = options ?? {};

    if (!this.options.disableDeepClone) {
      let options: Partial<ClientOptions> = {
        ...this.options,
        transport: undefined,
        twilsockClient: undefined,
      };

      options = deepClone(options);
      options.transport = this.options.transport;
      options.twilsockClient = this.options.twilsockClient;

      this.options = options;
    }

    this.options.logLevel = this.options.logLevel ?? "silent";
    log.setLevel(this.options.logLevel);

    const productId = (this.options.productId = "ip_messaging");

    // Filling ClientMetadata
    this.options.clientMetadata = this.options.clientMetadata || {};

    if (!this.options.clientMetadata.hasOwnProperty("type")) {
      this.options.clientMetadata.type = "conversations";
    }

    if (!this.options.clientMetadata.hasOwnProperty("sdk")) {
      this.options.clientMetadata.sdk = "JS";
      this.options.clientMetadata.sdkv = SDK_VERSION;
    }

    // Enable session local storage for Sync
    this.options.Sync = this.options.Sync || {};

    if (typeof this.options.Sync.enableSessionStorage === "undefined") {
      this.options.Sync.enableSessionStorage = true;
    }

    if (this.options.region) {
      this.options.Sync.region = this.options.region;
    }

    if (!fpaToken) {
      throw new Error("A valid Twilio token should be provided");
    }

    this.services = new ClientServices();

    this._myself = new User("", "", null, this.services);

    const startTwilsock = !this.options.twilsockClient;

    // Create default init registrations if none were provided.
    // Otherwise, the outside party have to list all the init registrations they
    // need.
    // Init registrations passed to the Conversations client will be passed down
    // to the Sync client as well.
    if (!this.options.initRegistrations) {
      const initRegistration = new InitRegistration(productId);
      Client.populateInitRegistrations(initRegistration);
      this.options.initRegistrations = [initRegistration];
    }

    this.services.twilsockClient = this.options.twilsockClient =
      this.options.twilsockClient ??
      new TwilsockClient(fpaToken, productId, this.options);

    this.services.twilsockClient.on("tokenAboutToExpire", (ttl) =>
      this.emit("tokenAboutToExpire", ttl)
    );
    this.services.twilsockClient.on("tokenExpired", () =>
      this.emit("tokenExpired")
    );
    this.services.twilsockClient.on("connectionError", (error) =>
      this.emit("connectionError", error)
    );
    this.services.twilsockClient.on(
      "stateChanged",
      (state: ConnectionState) => {
        log.debug(
          `Handling stateChanged for ConversationsClient: new state ${state}`
        );
        if (state !== this.connectionState) {
          this.connectionState = state;
          this.emit("connectionStateChanged", this.connectionState);
        }
      }
    );

    this.services.transport = this.options.transport = (this.options
      .transport ?? this.options.twilsockClient) as Transport;
    this.services.notificationClient = this.options.notificationsClient =
      this.options.notificationsClient ??
      new NotificationClient(fpaToken, this.options);
    this.services.syncClient = this.options.syncClient =
      this.options.syncClient ?? new SyncClient(fpaToken, this.options);

    const configurationOptions =
      options?.Chat || options?.IPMessaging || options || {};
    const region = configurationOptions.region || options?.region;
    const baseUrl: string =
      configurationOptions.apiUri ||
      configurationOptions.typingUri ||
      `https://aim.${region || "us1"}.twilio.com`;

    this.services.commandExecutor = new CommandExecutor(
      baseUrl,
      { transport: this.options.transport },
      productId
    );

    const emitFailed = (err): void => {
      this._rejectEnsureReady(err);
      this.emit("stateChanged", "failed");
    };

    this.services.twilsockClient.once("connectionError", emitFailed);
    this.services.twilsockClient.once("disconnected", emitFailed);

    // ConversationsClient will be able to initialize only after twilsock is connected
    this.services.twilsockClient.once("connected", async () => {
      log.debug(`ConversationsClient started INITIALIZING`);
      this.services.twilsockClient.off("connectionError", emitFailed);
      this.services.twilsockClient.off("disconnected", emitFailed);
      try {
        const startupEvent = "conversations.client.startup";

        this.services.twilsockClient.addPartialTelemetryEvent(
          new TelemetryEventDescription(
            startupEvent,
            "Conversations client startup",
            new Date()
          ),
          startupEvent,
          TelemetryPoint.Start
        );

        await this._initialize();

        this.services.twilsockClient.addPartialTelemetryEvent(
          new TelemetryEventDescription("", "", new Date()),
          startupEvent,
          TelemetryPoint.End
        );
      } catch (err) {
        // Fail ChatClient if initialization is incomplete
        this._rejectEnsureReady(err);
        this.emit("stateChanged", "failed");
      }
    });

    this._ensureReady = new Promise<void>((resolve, reject) => {
      this._resolveEnsureReady = resolve;
      this._rejectEnsureReady = reject;
    }).catch(() => void 0); // @todo How to process unhandled rejection here?

    if (startTwilsock) {
      this.services.twilsockClient.connect();
    }
  }

  public static populateInitRegistrations(reg: InitRegistration) {
    reg.populateInitRegistrations([NotificationTypes.TYPING_INDICATOR]);
    SyncClient.populateInitRegistrations(reg);
  }

  /**
   * Fired when a conversation becomes visible to the client. The event is also triggered when the client creates a new conversation.
   * Fired for all conversations client has joined.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  static readonly conversationAdded = "conversationAdded";

  /**
   * Fired when the client joins a conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  static readonly conversationJoined = "conversationJoined";

  /**
   * Fired when the client leaves a conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  static readonly conversationLeft = "conversationLeft";

  /**
   * Fired when a conversation is no longer visible to the client.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  static readonly conversationRemoved = "conversationRemoved";

  /**
   * Fired when the attributes or the metadata of a conversation have been updated.
   * During conversation's creation and initialization, this event might be fired multiple times
   * for same joined or created conversation as new data is arriving from different sources.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Conversation} `conversation` - the conversation in question
   *     * {@link ConversationUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly conversationUpdated = "conversationUpdated";

  /**
   * Fired when a participant has joined a conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly participantJoined = "participantJoined";

  /**
   * Fired when a participant has left a conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly participantLeft = "participantLeft";

  /**
   * Fired when a participant's fields have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Participant} `participant` - the participant in question
   *     * {@link ParticipantUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly participantUpdated = "participantUpdated";

  /**
   * Fired when a new message has been added to the conversation on the server.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  static readonly messageAdded = "messageAdded";

  /**
   * Fired when a message is removed from the message list of a conversation.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  static readonly messageRemoved = "messageRemoved";

  /**
   * Fired when the fields of an existing message are updated with new values.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} `message` - the message in question
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly messageUpdated = "messageUpdated";

  /**
   * Fired when the token is about to expire and needs to be updated.
   * * Parameters:
   * 1. number `message` - token's time to live
   * @event
   */
  static readonly tokenAboutToExpire = "tokenAboutToExpire";

  /**
   * Fired when the token has expired.
   * @event
   */
  static readonly tokenExpired = "tokenExpired";

  /**
   * Fired when a participant has stopped typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly typingEnded = "typingEnded";

  /**
   * Fired when a participant has started typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  static readonly typingStarted = "typingStarted";

  /**
   * Fired when the client has received (and parsed) a push notification via one of the push channels (apn or fcm).
   *
   * Parameters:
   * 1. {@link PushNotification} `pushNotification` - the push notification in question
   * @event
   */
  static readonly pushNotification = "pushNotification";

  /**
   * Fired when the client has subscribed to a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  static readonly userSubscribed = "userSubscribed";

  /**
   * Fired when the client has unsubscribed from a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  static readonly userUnsubscribed = "userUnsubscribed";

  /**
   * Fired when the properties or the reachability status of a user have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link User} `user` - the user in question
   *     * {@link UserUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  static readonly userUpdated = "userUpdated";

  /**
   * Fired when the state of the client has been changed.
   *
   * Parameters:
   * 1. {@link State} `state` - the new client state
   * @event
   */
  static readonly stateChanged = "stateChanged";

  /**
   * Fired when the connection state of the client has been changed.
   *
   * Paremeters:
   * 1. {@link ConnectionState} `state` - the new connection state
   * @event
   */
  static readonly connectionStateChanged = "connectionStateChanged";

  /**
   * Fired when the connection is interrupted for an unexpected reason.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * boolean `terminal` - Twilsock will stop connection attempts if true
   *     * string `message` - the error message of the root cause
   *     * number? `httpStatusCode` - http status code if available
   *     * number? `errorCode` - Twilio public error code if available
   * @event
   */
  static readonly connectionError = "connectionError";

  /**
   * @deprecated Call constructor directly.
   *
   * Factory method to create a Conversations client instance.
   *
   * The factory method will automatically trigger connection.
   * Do not use it if you need finer-grained control.
   *
   * Since this method returns an already-initialized client, some of the events
   * will be lost because they happen *before* the initialization. It is
   * recommended that `client.onWithReplay` is used as opposed to `client.on`
   * for subscribing to client events. The `client.onWithReplay` will re-emit
   * the most recent value for a given event if it emitted before the
   * subscription.
   *
   * @param token Access token.
   * @param options Options to customize the client.
   * @returns Returns a fully initialized client.
   */
  @validateTypesAsync("string", ["undefined", pureObject])
  static async create(
    token: string,
    options?: ClientOptions | null
  ): Promise<Client> {
    // The logic is as follows:
    // - If twilsock is not passed in, then the ConversationsClient constructor will call twilsock.connect() by itself
    //   and we do not need to do it here.
    // - If twilsock was passed in from the outside, but customer called ConversationsClient.create() then they are
    //   using an obsolete workflow and the startup sequence will never complete.
    if (options?.twilsockClient) {
      throw new Error(
        "Obsolete usage of ConversationsClient.create() " +
          "factory method: if you pass twilsock from the outside then you must " +
          "use ConversationsClient constructor and be prepared to work with " +
          "uninitialized client."
      );
    }

    const client = new Client(token, options);
    await client._ensureReady;

    return client;
  }

  /**
   * Information of the logged-in user. Before client initialization, returns an
   * uninitialized user. Will trigger a {@link Client.userUpdated} event after
   * initialization.
   */
  public get user(): User {
    return this._myself;
  }

  /**
   * Client reachability state. Throws if accessed before the client
   * initialization was completed.
   */
  public get reachabilityEnabled(): boolean {
    if (!this.configuration) {
      throw new Error(
        "Reachability information could not yet be accessed as the client " +
          "has not yet been initialized. Subscribe to the 'stateChanged' event " +
          "to properly react to the client initialization."
      );
    }

    return this.configuration.reachabilityEnabled;
  }

  public get token(): string {
    return this.fpaToken;
  }

  private _subscribeToPushNotifications(channelType: NotificationsChannelType) {
    [
      NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE,
    ].forEach((messageType) => {
      this.services.notificationClient.subscribe(channelType, messageType);
    });
  }

  private _unsubscribeFromPushNotifications(
    channelType: NotificationsChannelType
  ) {
    [
      NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE,
    ].forEach((messageType) => {
      this.services.notificationClient.unsubscribe(channelType, messageType);
    });
  }

  private async _initialize() {
    const configurationResponse =
      await this.services.commandExecutor.fetchResource<
        void,
        ConfigurationResponse
      >("Client/v2/Configuration");

    this.configuration = new Configuration(
      this.options as ClientOptions,
      configurationResponse,
      log
    );

    this._myself._resolveInitialization(
      this.configuration,
      this.configuration.userIdentity,
      this.configuration.userInfo,
      true
    );

    this.services.typingIndicator = new TypingIndicator(
      this.getConversationBySid.bind(this),
      this.configuration,
      this.services
    );
    this.services.network = new Network(this.configuration, this.services);

    this.services.users = new Users(
      this._myself,
      this.configuration,
      this.services
    );
    this.services.users.on("userSubscribed", (user) => {
      this.emit("userSubscribed", user);
    });
    this.services.users.on("userUpdated", (args: UserUpdatedEventArgs) =>
      this.emit("userUpdated", args)
    );
    this.services.users.on("userUnsubscribed", (user) => {
      this.emit("userUnsubscribed", user);
    });

    this.conversations = new ConversationsEntity(
      this.configuration,
      this.services
    );

    this.conversations.on("conversationAdded", (conversation) => {
      this.emit("conversationAdded", conversation);
    });
    this.conversations.on("conversationRemoved", (conversation) => {
      this.emit("conversationRemoved", conversation);
    });
    this.conversations.on("conversationJoined", (conversation) => {
      this.emit("conversationJoined", conversation);
    });
    this.conversations.on("conversationLeft", (conversation) => {
      this.emit("conversationLeft", conversation);
    });
    this.conversations.on(
      "conversationUpdated",
      (args: ConversationUpdatedEventArgs) =>
        this.emit("conversationUpdated", args)
    );

    this.conversations.on("participantJoined", (participant) => {
      this.emit("participantJoined", participant);
    });
    this.conversations.on("participantLeft", (participant) => {
      this.emit("participantLeft", participant);
    });
    this.conversations.on(
      "participantUpdated",
      (args: ParticipantUpdatedEventArgs) =>
        this.emit("participantUpdated", args)
    );

    this.conversations.on("messageAdded", (message) =>
      this.emit("messageAdded", message)
    );
    this.conversations.on("messageUpdated", (args: MessageUpdatedEventArgs) =>
      this.emit("messageUpdated", args)
    );
    this.conversations.on("messageRemoved", (message) =>
      this.emit("messageRemoved", message)
    );

    this.conversations.on("typingStarted", (participant) =>
      this.emit("typingStarted", participant)
    );
    this.conversations.on("typingEnded", (participant) =>
      this.emit("typingEnded", participant)
    );

    this.conversationsPromise = this.conversations
      .fetchConversations()
      .then(() => this.conversations)
      .catch((error) => {
        throw error;
      });

    await this.services.users.myself._ensureFetched();

    Client.supportedPushChannels.forEach((channelType) =>
      this._subscribeToPushNotifications(channelType)
    );
    this.services.typingIndicator.initialize();

    this.services.mcsClient = new McsClient(
      this.fpaToken,
      this.configuration.links.mediaService,
      this.configuration.links.mediaSetService,
      {
        ...this.options,
        transport: undefined,
      }
    );

    this._resolveEnsureReady();
    this.emit("stateChanged", "initialized");
  }

  /**
   * Gracefully shut down the client.
   */
  async shutdown(): Promise<void> {
    await this._ensureReady;
    await this.services.twilsockClient.disconnect();
  }

  /**
   * Update the token used by the client and re-register with the Conversations services.
   * @param token New access token.
   */
  @validateTypesAsync(nonEmptyString)
  async updateToken(token: string): Promise<Client> {
    await this._ensureReady;
    log.info("updateToken");

    if (this.fpaToken === token) {
      return this;
    }

    await this.services.twilsockClient.updateToken(token);
    await this.services.notificationClient.updateToken(token);
    await this.services.mcsClient.updateToken(token);
    this.fpaToken = token;

    return this;
  }

  /**
   * Get a known conversation by its SID.
   * @param conversationSid Conversation sid
   */
  @validateTypesAsync(nonEmptyString)
  async getConversationBySid(conversationSid: string): Promise<Conversation> {
    await this._ensureReady;
    await this.conversations.myConversationsRead.promise;
    let conversation = await this.conversations.getConversation(
      conversationSid
    );

    if (!conversation) {
      conversation = await this.conversations.peekConversation(conversationSid);
    }

    if (!conversation) {
      throw new Error(`Conversation with SID ${conversationSid} is not found.`);
    }

    return conversation;
  }

  /**
   * Get a known conversation by its unique identifier name.
   * @param uniqueName The unique identifier name of the conversation.
   */
  @validateTypesAsync(nonEmptyString)
  async getConversationByUniqueName(uniqueName: string): Promise<Conversation> {
    await this._ensureReady;
    await this.conversations.myConversationsRead.promise;
    const conversation = await this.conversations.getConversationByUniqueName(
      uniqueName
    );

    if (!conversation) {
      throw new Error(
        `Conversation with unique name ${uniqueName} is not found.`
      );
    }

    return conversation;
  }

  /**
   * Get the current list of all the subscribed conversations.
   */
  public async getSubscribedConversations(): Promise<Paginator<Conversation>> {
    await this._ensureReady;
    return this.conversationsPromise.then((conversations) =>
      conversations.getConversations()
    );
  }

  /**
   * Create a conversation on the server and subscribe to its events.
   * The default is a conversation with an empty friendly name.
   * @param options Options for the conversation.
   */
  @validateTypesAsync([
    "undefined",
    objectSchema("conversation options", {
      friendlyName: ["string", "undefined"],
      isPrivate: ["boolean", "undefined"],
      uniqueName: ["string", "undefined"],
    }),
  ])
  public async createConversation(
    options?: CreateConversationOptions
  ): Promise<Conversation> {
    await this._ensureReady;
    options = options || {};
    return this.conversationsPromise.then((conversationsEntity) =>
      conversationsEntity.addConversation(options)
    );
  }

  /**
   * Register for push notifications.
   * @param channelType Channel type.
   * @param registrationId Push notification ID provided by the FCM/APNS service on the platform.
   */
  @validateTypesAsync(literal("fcm", "apn"), "string")
  public async setPushRegistrationId(
    channelType: NotificationsChannelType,
    registrationId: string
  ): Promise<void> {
    await this._ensureReady;
    this._subscribeToPushNotifications(channelType);
    this.services.notificationClient.setPushRegistrationId(
      channelType,
      registrationId
    );
    await this.services.notificationClient.commitChanges(); // Committing before this point is useless because we have no push id
  }

  /**
   * Unregister from push notifications.
   * @param channelType Channel type.
   * @deprecated Use removePushRegistrations() instead.
   */
  @validateTypesAsync(literal("fcm", "apn"))
  public async unsetPushRegistrationId(
    channelType: NotificationsChannelType
  ): Promise<void> {
    await this._ensureReady;
    this._unsubscribeFromPushNotifications(channelType);
    await this.services.notificationClient.commitChanges();
  }

  /**
   * Clear existing registrations directly using provided device token.
   * This is useful to ensure stopped subscriptions without resubscribing.
   *
   * This function goes completely beside the state machine and removes all registrations.
   * Use with caution: if it races with current state machine operations, madness will ensue.
   *
   * @param channelType Channel type.
   * @param registrationId Push notification ID provided by the FCM/APNS service on the platform.
   */
  @validateTypesAsync(literal("fcm", "apn"), nonEmptyString)
  public async removePushRegistrations(
    channelType: ChannelType,
    registrationId: string
  ): Promise<void> {
    // do not await this._ensureReady() here - it could be called at any moment
    await this.services.notificationClient.removeRegistrations(
      channelType,
      registrationId
    );
  }

  private static parsePushNotificationChatData(
    data: Record<string, unknown>
  ): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const key in Client.supportedPushDataFields) {
      if (typeof data[key] === "undefined" || data[key] === null) {
        continue;
      }

      if (key !== "message_index") {
        result[Client.supportedPushDataFields[key]] = data[key];
        continue;
      }

      if (parseToNumber(data[key]) !== null) {
        result[Client.supportedPushDataFields[key]] = Number(data[key]);
      }
    }

    return result;
  }

  /**
   * Static method for push notification payload parsing. Returns parsed push as a {@link PushNotification} object.
   * @param notificationPayload Push notification payload.
   */
  @validateTypes(pureObject)
  static parsePushNotification(notificationPayload): PushNotification {
    log.debug(
      "parsePushNotification, notificationPayload=",
      notificationPayload
    );

    // APNS specifics
    if (typeof notificationPayload.aps !== "undefined") {
      if (!notificationPayload.twi_message_type) {
        throw new Error(
          "Provided push notification payload does not contain Programmable Chat push notification type"
        );
      }

      const data = Client.parsePushNotificationChatData(notificationPayload);

      const apsPayload = notificationPayload.aps;
      let body: string | null;
      let title: string | null = null;
      if (typeof apsPayload.alert === "string") {
        body = apsPayload.alert || null;
      } else {
        body = apsPayload.alert.body || null;
        title = apsPayload.alert.title || null;
      }

      return new PushNotification({
        title,
        body,
        sound: apsPayload.sound || null,
        badge: apsPayload.badge || null,
        action: apsPayload.category || null,
        type: notificationPayload.twi_message_type,
        data: data,
      });
    }

    // FCM specifics
    if (typeof notificationPayload.data !== "undefined") {
      const dataPayload = notificationPayload.data;
      if (!dataPayload.twi_message_type) {
        throw new Error(
          "Provided push notification payload does not contain Programmable Chat push notification type"
        );
      }

      const data = Client.parsePushNotificationChatData(
        notificationPayload.data
      );
      return new PushNotification({
        title: dataPayload.twi_title || null,
        body: dataPayload.twi_body || null,
        sound: dataPayload.twi_sound || null,
        badge: null,
        action: dataPayload.twi_action || null,
        type: dataPayload.twi_message_type,
        data: data,
      });
    }

    throw new Error(
      "Provided push notification payload is not Programmable Chat notification"
    );
  }

  public parsePushNotification = Client.parsePushNotification;

  /**
   * Handle push notification payload parsing and emit the {@link Client.pushNotification} event on this {@link Client} instance.
   * @param notificationPayload Push notification payload
   */
  @validateTypesAsync(pureObject)
  async handlePushNotification(notificationPayload): Promise<void> {
    await this._ensureReady;
    log.debug(
      "handlePushNotification, notificationPayload=",
      notificationPayload
    );
    this.emit(
      "pushNotification",
      Client.parsePushNotification(notificationPayload)
    );
  }

  /**
   * Gets a user with the given identity. If it's in the subscribed list, then return the user object from it;
   * if not, then subscribe and add user to the subscribed list.
   * @param identity Identity of the user.
   * @returns A fully initialized user.
   */
  @validateTypesAsync(nonEmptyString)
  public async getUser(identity: string): Promise<User> {
    await this._ensureReady;
    return this.services.users.getUser(identity);
  }

  /**
   * Get a list of subscribed user objects.
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    await this._ensureReady;
    return this.services.users.getSubscribedUsers();
  }
}

export {
  Client,
  State,
  ConnectionState,
  NotificationsChannelType,
  LogLevel,
  ClientOptions,
  CreateConversationOptions,
};
